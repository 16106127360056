.moneyflow__container {
    width: 100%;
    height: 100%;
    background: #5F4EA5;
    border-radius: 9px;
    position: relative;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer и старый Edge */
}

.moneyflow__container::-webkit-scrollbar {
    display: none; /* Скрыть скроллбар */
}

.background-circle {
    position: absolute;
    width: 704px;
    height: 704px;
    left: -502px;
    border-radius: 50%;
    top: -260px;
    background: linear-gradient(162.05deg,
            rgba(255, 153, 251, 0.49) 12.24%,
            rgba(71, 75, 164, 0.73) 40.34%,
            rgba(38, 34, 238, 0.15) 88.24%);
    z-index: 2;
}

.moneyflow__header {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin: 20px 0;
}

.moneyflow__list-wrapper {
    position: relative;
    padding: 0 46px;
    z-index: 2;
}

.moneyflow__list-item {
    display: flex;
    flex-direction: column;
    background: rgba(112, 143, 255, 0.5);
    box-shadow: 0px 4px 13px rgba(30, 30, 30, 0.29);
    border-radius: 9px;
    padding: 15px 25px;
    margin-bottom: 15px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.moneyflow__list-item p {
    margin: 5px 0;
}
.list__item-date {
    font-size: 12px;
    font-weight: 500;
    color: rgb(210, 210, 210);
}

.list__item-artist,
.list__item-album,
.list__item-category,
.list__item-description,
.list__item-amount,
.list__item-period,
.list__item-record-type,
.list__item-internal {
    font-size: 14px;
    font-weight: 500;
}

.no-reports {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-top: 20px;
}

.lds-ring__container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
