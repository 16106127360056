
.login__container {
    padding: 66px 62px 66px 62px;
    box-sizing: border-box;
    text-align: center;
}

.login__title {
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 15px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
}
.lds-ring__container.loginv{
    padding-top: 5px;
    width: auto;
    height: auto;
}
.lds-ring__container.loginv .lds-ring {
    width: 50px;
}
.lds-ring__container.loginv .lds-ring div {
    width: 40px;
    height: 40px;
    border: 3px solid white;
    border-color: #fff transparent transparent transparent;
}

.login {
    max-width: 643px;
    box-sizing: border-box;
    position: relative;
    height: 410px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 150px;
    padding: 42px 140px 79px 140px;
    background-color: rgb(38, 38, 38);
    box-shadow: rgba(17, 17, 17, 0.5) 0px 0px 8px 0px;
    border-radius: 5px;
}
.bg-circle{
    position: absolute;
    width: 804px;
    left: -424px;
    top: -260px;
    height: 804px;
    border-radius: 50%;
    background: linear-gradient(162.05deg, rgba(255, 153, 251, 0.49) 12.24%, rgba(71, 75, 164, 0.73) 40.34%, rgba(38, 34, 238, 0.15) 88.24%);
}
.login__form-title{
    color: #fff;
    position: relative;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    z-index: 3;
    text-transform: uppercase;
}

.login__form{
    z-index: 3;
    position: relative;
    width: 100%;
}

.login__form-field{
    text-align: left;
}
.login__form-field {
    padding-bottom: 34px;
    position: relative;
}
.login__form-field.last-child{
    padding-bottom: 30px;
}
.login__form-field label {
    display: block;
    padding-bottom: 3px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
.button__text{
    text-transform: uppercase;
    letter-spacing: 0.135em;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    margin: 0 auto;
    font-weight: 700;
    font-size: 13px;
}
.login__form-button{

    position: relative;
    display: flex;
    align-items: center;
}
.login__form-button.button--loading .button__text {
    visibility: hidden;
    opacity: 0;
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}
.login__form-input{
    transition: all ease .2s;
    width: 100%;
    height: 38px;
    font-size: 16px;
    padding-left: 21px;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    background-color: #ffffff;
    color: #000000;
}
.login__form-input:focus {
    outline: 2px solid #BB77FF;
}
.login__form-button{
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 0.135em;
    transition: all ease .5s;
    width: 100%;
    color: #fff;
    background: linear-gradient(93.17deg, #0CFFC5 -0.79%, #708FFF 72.7%);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    border-radius: 13px;
    border: none;
    height: 38px;
}
.login__form-button:hover {
    background: linear-gradient(93.17deg, #0CFFC5 -0.79%, #708FFF 52.7%);
}
.login__form-error{
    font-weight: 300;
    text-align: right;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 0;
    padding-top: 20px;
    font-size: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    color: #FF7E9D;
}
.login__form-input.login-error{
    outline: 2px solid #FF7E9D;
}
.login__form-props{
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    align-items: center;
}

.remember-me{
    display: flex;
    align-items: center;
}

.remember-me p {
    padding-left: 7px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    color: #fff;
    font-size: 16px;
}

.remember-me input {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 2px;
}
.remember-me input{
    accent-color: aquamarine;
}

.forgot-password a{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;

}
.forgot-password a:hover {

color: rgb(100, 127, 247);
}

.login__title div{
    color: #FF7E9D;
}
span {
    color: #FF7E9D;
}

@media (max-width: 1560px) {
    .login__container {
        margin: 0 auto;
        padding: 38px 39px 38px 39px;
    }
    .lds-ring__container.loginv{
        padding-top: 3px;
        width: auto;
        height: auto;
    }
    .lds-ring__container.loginv .lds-ring{
        width: 55px;
    }
    .lds-ring__container.loginv .lds-ring div {
        width: 30px;
        height: 30px;
        border: 3px solid white;
        border-color: #fff transparent transparent transparent;
    }

    .login {
        max-width: 523px;
        height: 335px;
        margin-bottom: 54px;
        padding: 24px 83px 79px 83px;
    }
    .login__form-title {
        font-size: 26px;
    }
    .login__form-field {
        padding-bottom: 20px;
    }
    .login__form-field label {
        padding-bottom: 5px;
        font-size: 15px;
    }
    .login__form-input {
        font-family: 'Montserrat';
        font-size: 15px;
    }
    .login__form-props {
        margin-bottom: 16px;
    }
    .remember-me input{
        width: 11px;
        height: 11px;
    }
    .remember-me p {
        font-size: 14px;
    }
    .forgot-password a {
        font-size: 14px;
    }
    .button__text{
        font-size: 11px;
    }
    .login__form-button {
        height: 38px;
    }
    .login__form-field.last-child{
        padding-bottom: 14px;
    }
}
@media (max-width: 768px) {
    .login__container{
        padding: 15px 20px 15px 20px;
    }
}
@media (max-width: 800px) {
    .login{
        padding: 10px 30px 10px 30px;
    }
}

@media (max-width: 565px) {
    .login__form-props{
        display: block;
        text-align: center;
    }
    .remember-me {
        margin-bottom: 25px;
    }
}

@media (max-width: 1016px) {
    .login__title{
        top: 40px;
        left: 35px;
    }
}

@media (max-width: 932px) {
    .login__title{
        top: 30px;
        left: 20px;
        font-size: 33px;
    }
}

@media (max-width: 425px) {
    .login{
        height: 400px;
        padding: 10px 10px 10px 10px;
    }
    .login__form-error{
        padding-top: 5px;
    }
    .login__form-title{
        font-size: 30px;
    }
    .login__form-field label{
        font-size: 20px;
    }
    .login__form-field{
        padding-bottom: 20px;
    }
    .login__form-button{
        height: 42px;
    }
    .login__form-input{
        height: 35px;
    }
    .remember-me p {
        font-size: 15px;
    }
    .forgot-password a {
        font-size: 15px;
    }
}
.remove-btn {
    margin-right: 0.5rem;
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.server__errors{
    width: 100%;
    height: 100%;
    background: rgba(255, 126, 157, 0.64);
    border: 1px solid #DD96A7;
    border-radius: 8px;
    margin-bottom: 15px;
}
.server__errors p {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
}
.password-recovery-info {
    padding-top: 20px;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;
}