*{
    padding: 0;
    margin: 0;
}
.App {
    min-width: 100%;
    min-height: 100%;
}

.background{
}