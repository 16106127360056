.profile__container{
    width: 100%;
    background: #5F4EA5;
    padding: 47px 48px 47px 47px;
    overflow: hidden;
    border-radius: 9px;
    position: relative;
}

.background-circle{
    position: absolute;
    width: 804px;
    height: 804px;
    left: -424px;
    border-radius: 50%;
    top: -260px;
    background: linear-gradient(162.05deg,
    rgba(255, 153, 251, 0.49) 12.24%,
    rgba(71, 75, 164, 0.73) 40.34%,
    rgba(38, 34, 238, 0.15) 88.24%);
}
@media (max-width: 768px) {
    .background-circle{
        height: 745px;
        width: 745px;
    }
}
@media (max-width: 425px) {
    .background-circle{
        height: 760px;
        width: 760px;
    }
}

@media (max-width: 325px) {
    .background-circle{
        height: 660px;
        width: 660px;
    }
}

.profile__form-wrapper{
    background: rgba(112, 143, 255, 0.31);
    box-shadow: 0px 4px 13px rgba(30, 30, 30, 0.29);
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 78px;
    z-index: 3;
    border-radius: 9px;
    width: 100%;
    /*padding: 47px 48px 47px 47px;*/
    height: 100%;
}

.profile__form{
    max-width: 1361px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
}
.profile__form-label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    align-items: center;
    color: #FFFFFF;
}
.profile__form-left, .profile__form-right{
    width: 597px;
}
.profile__form-left input::placeholder, .profile__form-right input::placeholder{
    color: #C2C2C2;
}
.profile__form-left input, .profile__form-right input{
    display: block;
    width: 100%;
    margin-top: 17px;
    margin-bottom: 35px;
    font-size: 23px;
    padding-left: 21px;
    background: transparent;
    height: 64px;
    font-family: 'Montserrat';
    font-style: normal;
    outline: none;
    border: 1px solid;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: 10px;
    border-color: #FFFFFF;
    flex: none;
}
input.error{
    border: 2px solid red;
}
.profile__submit-btn{
    cursor: pointer;
    margin-top: 44px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    outline: none;
    letter-spacing: 0.105em;
    text-transform: lowercase;
    border: none;
    color: #FFFFFF;
    width: 100%;
    background: #A54DFD;
    border-radius: 10px;
    height: 64px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.profile__form-left span, .profile__form-right span{
    font-weight: 400;
}
@media (max-width: 1560px) {
    .profile__container{
        padding: 25px 35px 25px 35px;
    }
    .profile__form{
        justify-content: space-around;
    }
    .profile__form-left, .profile__form-right {
        width: 366px;
    }
    .profile__form-wrapper input.last-child{
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .profile__form-wrapper {
        padding-top: 47px;
        padding-bottom: 47px;
    }
    .profile__form-label {
        font-size: 14px;
    }
    .profile__form-left input, .profile__form-right input {
        margin-top: 11px;
        margin-bottom: 20px;
        font-size: 14px;
        height: 39px;
    }
    .profile__submit-btn {
        margin-top: 47px;
        font-size: 15px;
        text-transform: uppercase;
        height: 39px;
    }
}
@media (max-width: 1395px) {
    .profile__submit-btn{
        margin-top: 51px;
    }
    .profile__form-label{
        font-size: 18px;
    }
    .profile__form-left input, .profile__form-right input{
        margin-top: 10px;
        bottom: 15px;
    }
    .profile__form-left, .profile__form-right{
        width: 480px;
    }
}

@media (max-width: 1150px) {
    .profile__form-left, .profile__form-right{
        width: 415px;
    }
    .profile__form-label{
        font-size: 16px;
    }
    .profile__container{
        padding: 47px 15px 47px 15px;
    }
}

@media (max-width: 950px) {
    .profile__form{
        display: block;
    }
    .profile__form-left, .profile__form-right{
        width: 100%;
    }
    .profile__form-wrapper{
        padding-top: 20px;
        padding-bottom: 10px;
    }
}
@media (max-width: 762px) {
    .profile__form-left input, .profile__form-right input {
        height: 40px;
    }
}
