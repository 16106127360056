.dashboard__container {
    width: 100%;
    height: 100%;
    background: #5F4EA5;
    border-radius: 9px;
    position: relative;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer и старый Edge */
}

.dashboard__container::-webkit-scrollbar {
    display: none; /* Скрыть скроллбар */
}

.logo__wrapper .logo {
    height: 15px;
}

.background-circle {
    position: absolute;
    width: 704px;
    height: 704px;
    left: -502px;
    border-radius: 50%;
    top: -260px;
    background: linear-gradient(162.05deg,
            rgba(255, 153, 251, 0.49) 12.24%,
            rgba(71, 75, 164, 0.73) 40.34%,
            rgba(38, 34, 238, 0.15) 88.24%);
}

.dashboard__top-wrapper {
    width: 100%;
    background: #fff;
}

.dashboard__top {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    z-index: 3;
    padding: 15px 0px 15px 0px;
    background: #fff;
}

.list__item-doc img {
    width: 29px;
    height: 29px;
}

.period-name,
.count-name,
.sum-name,
.balance-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    display: none;
}

.period-name {
    flex: none;
    /* width: 35.4vw; */
    text-align: center;
    /* padding-left: 140px; */
}

.request-reward:disabled {
    cursor: default;
    background: #c298ff;
}

.count-name {
    flex: none;
    width: 35%;
    text-align: right;
}

.list__item-approvement-btn.approved {
    cursor: default;
}

.balance-name {
    flex: none;
    text-align: center;
}

.dashboard__list {
    padding: 0 46px 0 47px;
}

.dashboard__list-item {
    position: relative;
    z-index: 3;
}

.list__item-quarter,
.list__item-auditions,
.list__item-total,
.list__item-sum {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.list__item-sum {
    width: 200px;
}

.dashboard__list {
    width: 100%;
    padding-top: 47px;
    margin: 0 auto;
}

.list__item-approvement-btn div {
    margin: 0 auto;
}

.list__item-approvement-btn {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
}

.list__item-approvement-btn .item-button__text {
    /* visibility: hidden;
    opacity: 0; */
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

.payout_button-info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    width: 450px;
    color: #FFFFFF;
}

.statement_dates-info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    display: flex;
    color: #FFFFFF;
}

.list__item-doc__wrapper-left {
    display: flex;
    justify-content: center;
    width: 45%;
}

.dashboard__list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background: rgba(112, 143, 255, 0.5);
    box-shadow: 0px 4px 13px rgba(30, 30, 30, 0.29);
    border-radius: 9px;
    padding: 15px 25px 15px 25px;
    margin-bottom: 15px;
}

.list__item-quarter,
.list__item-auditions,
.list__item-total {}

.list__item-doc__wrapper {
    flex: none;
    width: 50px ;
}

.page-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 20px;
}

.list__item-doc {
    cursor: pointer;
}

.list__item-doc:hover {
    opacity: 0.7;
}

.list__item-doc:active {
    opacity: 0.5;
}

.list__item-quarter {
    /* flex: none; */
    /* text-align: center; */
    width: 200px;
    /* min-width: 130px; */
    /* padding-left: 20px; */
}

.list__item-auditions {
    flex: none;
    width: 31%;
}

.list__item-total {
    flex: none;
    text-align: center;
    /* text-align: right; */
    overflow-wrap: anywhere;
    /* padding-right: 55px; */
    /* width: 34vw; */
}

.list__item-download-btn__wrapper {
    /* flex: none; */
    width: 10%;
    min-width: 169px;
    position: absolute;
    right: 2vw;

}

.list__item-approvement-btn__wrapper {
    /* flex: none; */
    width: 10%;
    min-width: 169px;
    position: absolute;
    right: 13vw;
}

.list__item-approvement-btn {
    cursor: pointer;
    width: 100%;
    height: 34px;
    border: 1px solid #FF8585;
    color: #FF8585;
    background: transparent;
    letter-spacing: 0.105em;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    border-radius: 10px;
}

.list__item-approvement-btn:hover {
    background: rgba(255, 133, 133, 0.1);
}

.list__item-approvement-btn.approved {
    border: 1px solid #77F588;
    color: #77F588;
}

.list__item-approvement-btn.approved:hover {
    background: rgb(119, 245, 136, 0.1);
}

.list__item-download-btn {
    cursor: pointer;
    width: 100%;
    height: 34px;
    text-transform: uppercase;
    outline: none;
    border: none;
    background: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.105em;
    font-size: 11px;
    border-radius: 10px;
}

.list__item-download-btn:hover {
    opacity: 0.7;
}

.list__item-download-btn:active {
    opacity: 0.5;
}

.total__payment {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 21px;
    color: #FFFFFF;
}

.dashboard__list-wrapper {
    /* min-width: 783px; */
    overflow-y: auto;
    overflow-x: unset !important;
    max-height: 565px;
    padding: 0 7px 0 7px;
    margin-bottom: 20px;
}

.request-reward {
    cursor: pointer;
    width: 350px;
    height: 50px;
    background: #A54DFD;
    text-transform: uppercase;
    outline: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
    border: none;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.request-reward:hover:enabled {
    opacity: 0.7;
}

.lds-ring {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    width: 80px;
    padding-top: 60px;
    padding-bottom: 140px;
    height: 80px;
}

.no-reports {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    top: -9%;
    align-items: center;
    text-align: center;
}

.no-reports div {
    margin: 0 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: calc(15px + 15 * (100vw / 1920));
    color: #fff;
}

p.list__item-auditions {
    padding-left: 67px;
}

.no-reports div span {
    padding-left: 10px;
    font-weight: 600;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

/*.request-reward{*/
/*    margin-bottom: 42px;*/
/*}*/
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.total__payment {
    position: relative;
    z-index: 3;
}

/*todo: avoid this poop code*/

.lds-ring__container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.payout_button-info {
    position: relative;
    z-index: 3;
}

@media (max-width: 1560px) {

    .period-name,
    .count-name,
    .sum-name,
    .balance-name {
        font-size: 13px;
    }

    /* .period-name{
        padding-left: 120px;
        width: 28%;
    } */
    .payout_button-info {
        width: 350px;
        font-size: 13px;
    }

    .total__payment {
        margin-bottom: 13px;
    }


    .request-reward {
        border-radius: 5px;
    }

    .dashboard__list-item {
        padding: 13px 25px;
        margin-bottom: 15px;
    }

    .list__item-download-btn {
        height: 30px;
    }

    .list__item-approvement-btn__wrapper {
        right: 12vw;
        width: 120px;
        min-width: 0;
    }

    .list__item-download-btn__wrapper {
        width: 120px;
        min-width: 0;
    }

    .list__item-approvement-btn {
        height: 30px;
        font-size: 9.21996px;
        border-radius: 5px;
    }

    .list__item-quarter,
    .list__item-auditions,
    .list__item-total,
    .list__item-sum {
        font-size: 13px;
    }

    .list__item-download-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 9.21996px;
        letter-spacing: 0.105em;
        color: #000000;
    }

    /* .dashboard__container{
        height: 486px;
    } */
    /* .dashboard__list-wrapper {
        max-height: 290px;
    } */

    .dashboard__top {
        padding: 15px 0px 14px 0px;
    }

    .request-reward {
        width: 230px;
        height: 50px;
        font-size: 11px;
        letter-spacing: 0.105em;
        text-transform: uppercase;
    }

    .total__payment {
        font-size: 14px;
    }

    .list__item-doc img {
        display: flex;
        width: 30px;
        height: 30px;
    }

    /* .count-name{
        width: 34%;
    } */
}

@media (max-width: 1200px) {
    .list__item-approvement-btn__wrapper {
        right: 14vw;
    }
}

@media (max-width: 1140px) {

    /* .list__item-approvement-btn__wrapper{
        width: 13%;
    }
    .list__item-download-btn__wrapper {
        width: 13%;
    } */
    /* .list__item-total {
        width: 40%;
    } */
    .dashboard__list {
        padding: 47px 15px 0 15px;
    }

    .period-name {
        width: 32vw;
    }

    .count-name {
        width: 35%;
    }

    /* .balance-name{
        width: 21%;
        padding-right: 67px;
    } */
    /* .period-name {
        padding-left: 90px;
    } */
}

@media (max-width: 1024px) {

    /* .list__item-download-btn__wrapper{
        display: none;
    } */
    .dashboard__list {
        padding: 47px 15px 0 15px;
    }

    /* .period-name{
        padding-left: 70px;
    } */

    .count-name {
        width: 46%;
    }

    /* .balance-name{
        width: 26%;
        padding-right: 52px;
    } */
    /* .dashboard__list-item{
        padding: 14px 12px 13px 12px;
    } */
    /* .list__item-quarter{
        width: 42%;
    } */
    .list__item-auditions {
        width: 38%;
    }

    /* .list__item-total{
        width: 17.5%;
    } */
}


@media (max-width: 1025px) {
    /* .period-name{
        width: 27%;
    } */
    /* .dashboard__list-item{
        padding: 14px 12px 13px 12px;
    } */
    /* .list__item-quarter{
        width: 38%;
    }
    .list__item-auditions{
        width: 38%;
    } */

    .list__item-approvement-btn__wrapper {
        right: 19vw;
    }

    .list__item-download-btn__wrapper {
        right: 3vw;
    }
}


@media (max-width: 768px) {

    .period-name,
    .sum-name,
    .balance-name {
        font-size: 11px;
    }

    .list__item-quarter,
    .list__item-auditions,
    .list__item-total,
    .list__item-sum {
        font-size: 10px;
    }



    .list__item-doc__wrapper {
        padding-right: 10px;
    }

    .list__item-quarter {
        width: 18vw;
    }

    /* .list__item-quarter {
        width: 50%;
    } */

    /* .balance-name {
        padding-right: 0px;
        padding-left: 40px;
        width: auto;
    } */


    /* .dashboard__list-item{
        width: 766px;
    } */
    /* .dashboard__top{
        width: 797px;
    } */
    /* .count-name{
        width: 46%;
    } */
    /* .dashboard__container{
        overflow: auto;
    } */
    /* .list__item-total {
        padding-left: 40px;
        width: 30%;
    } */
    /* .list__item-quarter {
        width: auto;
    } */


}

@media (max-width: 768px) {
    .payout_button-info {
        width: 100%;
    }

    .list__item-approvement-btn__wrapper {
        position: unset;
    }

    .list__item-download-btn__wrapper {
        position: unset;
    }

    .list__item-quarter {
        /* padding-left: 7px; */
        width: 30%;
    }

    .period-name {
        width: 50%;
    }

    .balance-name {
        width: 50%;
    }

    .cabinet__balance-inner {
        width: 200px;
        font-size: 13px;
    }

    .dashboard__list-wrapper {
        padding: 0 4px;
        height: 300px;
    }

    .list__item-doc__wrapper {
        height: 29px;
    }



    .dashboard__list {
        padding: 20px 15px 0;
    }

    /* .dashboard__container {
        height: 485px;
    } */
    .list__item-approvement-btn__wrapper,
    .list__item-download-btn__wrapper {
        margin-right: 0px;
        width: 100%;
        padding: 8px 0 0 0;
    }
}

@media (max-width: 430px) {
    .list__item-quarter {
        /* padding-left: 7px; */
        width: auto;
    }
}

@media (max-width: 380px) {

    .period-name {
        width: 50%;
    }

    .balance-name {
        width: 50%;
    }

    .cabinet__balance-inner {
        width: 165px;
    }
}

@media (max-width: 325px) {

    .period-name {
        width: 50%;
    }

    .balance-name {
        width: 50%;
    }

    .dashboard__list-wrapper {
        padding: 0 4px;
    }

    .list__item-quarter {
        /* padding-left: 15px; */
    }

    .list__item-doc__wrapper {
        height: 29px;
    }

    .list__item-total {
        width: auto;
    }

    .dashboard__list {
        padding: 30px 15px 0;
    }

    .dashboard__list-wrapper {
        margin-bottom: 50px;
    }

    /* .dashboard__container {
        height: 365px;
    } */
}