.payoutRequest__container{
    width: 100vw;
    min-width: 300px;
    max-width: 1250px;
    background: #5F4EA5;
    padding: 40px;
    overflow: hidden;
    box-shadow: 0px 4px 13px rgba(255, 255, 255, 0.1);
    border-radius: 9px;
    position: relative;
}
.payoutRequestConfirmation {
    width: 470px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.payoutRequestConfirmation-textblock {
    margin-bottom: 20px;
    row-gap: 13px;
    display: flex;
    flex-direction: column;
}

.payoutRequestConfirmation-text {
    margin: 0;
    margin-bottom: 20px;
    color: #ffffff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.payoutRequestConfirmation-text2 {
    margin: 0;
    color: #ffffff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    text-align: center;
}

.background-circle{
    position: absolute;
    width: 804px;
    height: 804px;
    left: -424px;
    border-radius: 50%;
    top: -260px;
    background: linear-gradient(162.05deg,
    rgba(255, 153, 251, 0.49) 12.24%,
    rgba(71, 75, 164, 0.73) 40.34%,
    rgba(38, 34, 238, 0.15) 88.24%);
}

.payoutRequest__form-wrapper{
    background: rgba(112, 143, 255, 0.31);
    box-shadow: 0px 4px 13px rgba(30, 30, 30, 0.29);
    position: relative;
    padding: 40px 20px;
    z-index: 3;
    border-radius: 9px;
    display: flex;
    justify-content: center;
}

.payoutRequest__form {
    width: 1361px;
    margin: 0 auto;
}

.payoutRequest__form-heading {
    margin: 0;
    margin-bottom: 20px;
    color: #ffffff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    text-align: center;
}

.payoutRequest__form-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 30px;
    width: 100%;
    margin: 0;
    padding: 0 54px;
    list-style: none;
}

.payoutRequest__form-item:not(:last-child) {
    margin-bottom: 14px;
}

.payoutRequest__form-label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    color: #FFFFFF;
}

.payoutRequest__form-left, .payoutRequest__form-right{
    width: 597px;
}

.payoutRequest__form-right{
    justify-self: end;
}

.payoutRequest__form-left input, .payoutRequest__form-right input, 
.payoutRequest__form-left select, .payoutRequest__form-right select,
.payoutRequest__form-left textarea, .payoutRequest__form-right textarea {
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 35px;
    font-size: 14px;
    padding-left: 21px;
    background: transparent;
    border: 1px solid #fff;
    height: 64px;
    font-family: 'Montserrat';
    font-style: normal;
    outline: none;
    font-weight: 500;
    color: #fff;
    border-radius: 13px;
    flex: none;
}
.payoutRequest__form-left input:hover, .payoutRequest__form-right input:hover,
.payoutRequest__form-left select:hover, .payoutRequest__form-right select:hover,
.payoutRequest__form-left textarea:hover, .payoutRequest__form-right textarea:hover {
    border: 2px solid #fff;
}

.payoutRequest__form-left input:focus, .payoutRequest__form-right input:focus,
.payoutRequest__form-left select:focus, .payoutRequest__form-right select:focus,
.payoutRequest__form-left textarea:focus, .payoutRequest__form-right textarea:focus {
    border: 2px solid #fff;
    background: #0000002c;;
}

.payoutRequest__form-left select, .payoutRequest__form-right select {
    cursor: pointer;
}

.payoutRequest__form-left select option, .payoutRequest__form-right select option {
    color: #000;
}

.payoutRequest__form-left textarea,
.payoutRequest__form-right textarea {
    height: auto;
    min-height: 201px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.payoutRequest__form-left textarea::-webkit-scrollbar,
.payoutRequest__form-right textarea::-webkit-scrollbar {
    display: none;
}

.payoutRequest__form-info a {
    text-decoration: none;
    color: #93C4FF;
}

.payoutRequest__form-info span {
    color: #ffffff;
    font-weight: 600;
}
.payoutRequest__form-info-wrapper-upper-upper {
    grid-column: span 2;
}
.payoutRequest__form-info-wrapper-upper {

    background: #0000001A;
    border-radius: 5px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    align-items: center;
    column-gap: 20px;
    
}

.payoutRequest__form-info-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.payoutRequest__form-info {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    line-height: 25px;
}

input.error{
    border: 1px solid red;
}

.payoutRequest__submit-btn{
    grid-column: 2 / 3;
    justify-self: end;
    margin-top: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    outline: none;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 452px;
    height: 50px;
    background: transparent;
    border: 2px solid #A54DFD;
    border-radius: 13px;
    cursor: pointer;
}

.payoutRequest__submit-btn:hover {
    background: rgba(165, 77, 253, 0.5);
}

.payoutRequest__submit-btn:active {
    background: rgba(165, 77, 253, 0.3);
}

.payoutRequest__submit-btn:disabled {
    color: #A0A0A0;
    background: rgba(165, 77, 253, 0.2);
    border: 2px solid rgba(165, 77, 253, 0.2);
}

.payoutRequest__form-left span, .payoutRequest__form-right span{
    font-weight: 400;
}

.error-message {
    font-family: 'Montserrat';
    color: red;
    font-size: 14px;
    margin-top: 0px;
}

::placeholder {
    color: #fff;
    opacity: 0.7;
}

textarea::placeholder {
    line-height: 60px;
}

.infoicon {
    height: 20px;
}
.doneicon {
    height: 64px;
    margin-bottom: 30px;
}

@media (max-width: 1560px) {
    .payoutRequest__form-left, .payoutRequest__form-right {
        width: 452px;
    }
    .payoutRequest__form{
        width: 1100px;
    }
    .payoutRequest__form{
        margin-bottom: 25px;
    }
    .payoutRequest__form-list {
        padding: 0 64px;
    }

    .payoutRequest__form-wrapper input.last-child{
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .payoutRequest__form-label {
        font-size: 14px;
    }

    .payoutRequest__form-left input, .payoutRequest__form-right input,
    .payoutRequest__form-left select, .payoutRequest__form-right select,
    .payoutRequest__form-left textarea, .payoutRequest__form-right textarea {
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 8px;
        font-size: 14px;
        padding: 14px 21px;
        background: transparent;
        border: 1px solid #fff;
        height: 50px;
        font-family: 'Montserrat';
        font-style: normal;
        outline: none;
        font-weight: 500;
        color: #fff;
        border-radius: 13px;
        resize: none;
    }

    .payoutRequest__form-left textarea, .payoutRequest__form-right textarea {
        height: auto;
        min-height: 145px;
    }

    .payoutRequest__form-left select, .payoutRequest__form-right select {
        cursor: pointer;
    }

    .payoutRequest__form-left select option, .payoutRequest__form-right select option {
        color: #000;
    }

    .payoutRequest__form-info{
        font-size: 14px;
        line-height: 20px;
    }

    /* .payoutRequest__submit-btn {
        width: 452px;
        margin-top: 43px;
        font-size: 14px;
        text-transform: uppercase;
    } */

    textarea::placeholder {
        line-height: 20px;
    }
}

@media (max-width: 1395px) {
    .payoutRequest__form-wrapper{
        padding: 30px 15px;
    }
    .payoutRequest__form{
        width: 980px;
    }
    .payoutRequest__form-list {
        padding: 0 48px;
    }
    .payoutRequest__form-left, .payoutRequest__form-right{
        width: 420px;
    }
    .payoutRequest__submit-btn{
        width: 420px;
        margin-top: 43px;
    }
    .payoutRequest__form-label{
        font-size: 14px;
    }
    .payoutRequest__form-wrapper{
        padding-right: 0;
        padding-left: 0;
    }
    .payoutRequest__form-left input, .payoutRequest__form-right input{
        margin-top: 10px;
        bottom: 15px;
    }
}

@media (max-width: 1150px) {
    .payoutRequest__form-wrapper{
        padding: 20px 10px 10px;
    }
    .payoutRequest__form{
        width: 810px;
    }
    .payoutRequest__form-list {
        padding: 0 16px;
    }
    .payoutRequest__form-left, .payoutRequest__form-right{
        width: 380px;
    }
    .payoutRequest__submit-btn{
        width: 380px;
        margin-top: 21px;
    }
    .payoutRequest__form-label{
        font-size: 14px;
    }
    .payoutRequest__container{
        padding: 47px 15px 47px 15px;
    }
}

@media (max-width: 950px) {
    .payoutRequest__form{
        width: 100%;
    }

    .payoutRequest__form-list {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
    }

    .payoutRequest__form-item:not(:last-child) {
        margin-bottom: 12px;
    }

    .payoutRequest__form-left .payoutRequest__form-item {
        margin-bottom: 12px;
    }

    .payoutRequest__form-left, .payoutRequest__form-right {
        width: 100%;
    }

    .payoutRequest__submit-btn{
        width: 100%;
    }

    .payoutRequest__form-wrapper{
        padding: 20px 10px 10px;
    }
}

@media (max-width: 762px) {
    .payoutRequest__form-left input, .payoutRequest__form-right input,
    .payoutRequest__form-left select, .payoutRequest__form-right select,
    .payoutRequest__form-left textarea, .payoutRequest__form-right textarea {
        height: 40px;
    }

    .payoutRequest__form-left select, .payoutRequest__form-right select {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    textarea::placeholder {
        line-height: 20px;
    }
}
