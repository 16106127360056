@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;1,300;1,400&display=swap');
*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background: rgb(70,67,113);
  background: -moz-linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
  background: -webkit-linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
  background: linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
  background: linear-gradient(90deg, rgba(45, 41, 94, 0.88) 2.57%, rgba(0, 0, 0, 0.86) 122.31%);
  background: linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
  width: 100%;
  height: 100%;
  max-width:100%;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* Скрываем скроллбар */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
