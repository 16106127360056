.modal{
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    visibility: hidden;
    transform: scale(0);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-height: 780px) {
        height: 100%;
        overflow: auto;
        align-items: flex-start;
    }
}
.cross-close {
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;
    z-index: 100;
    border: 1px solid #fff;
    border-radius: 50%;

    @media (max-width: 1150px) {
        top: 8px;
        right: 15px;
    }
}
.cross-close:hover {
    opacity: 0.7;
}
.cross-close:active {
    opacity: 0.5;
}
.cross-close:before, .cross-close:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2.5px;
    background: #fff;
    top: 13px;
    right: 4px;
}
.cross-close:before {
    transform: rotate(45deg);
}
.cross-close:after {
    transform: rotate(-45deg);
}
.modal.active{
    visibility: visible;
    transform: scale(1);
}

.modal.active .modal_content{
    visibility: visible;
    transform: scale(1);
}

.modal_content{
    transition: all ease .3s;
    padding: 0px;
    border-radius: 12px;
    visibility: hidden;
    transform: scale(0);
    max-height: 90vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.modal_content::-webkit-scrollbar {
    display: none;
}