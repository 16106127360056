.cabinet__container{
    padding: 20px 47px 54px 54px;
}

.cabinet__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}
.cabinet__top-info{
    padding-bottom: 10px;
}
.cabinet__title{
    font-family: 'Montserrat';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: calc(15px + 10 * (100vw / 1920));
    color: #fff;
}

.cabinet__title span {
    display: block;
    color: #ff7e90;
    font-weight: 500;
    /* font-size: 15px; */
    text-transform: none;
}

.cabinet__top-list{
    list-style: none;
    display: flex;
}

ul.cabinet__top-list > li:not(:last-child){
    padding-right: 76px;
}
.cabinet__top-info{
    display: flex;
    justify-content: space-between;
}
.cabinet__balance-inner{
    width: 350px;
    padding: 7px 15px 7px 15px;
    font-family: "Montserrat";
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    border: 1.37059px solid #1AFF75;
    border-radius: 5.48235px;
}
.cabinet__top-list__item a{
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    color: #fff;
}
.cabinet__my-profile{
}
.cabinet__my-profile img{
    padding-left: 13px;
}
.cabinet__my-profile-wrapper{
    display: flex;
    align-items: center;
}
.cabinet__my-profile-wrapper img{
    cursor: pointer;
}
.cabinet__my-profile{
    font-family: 'Montserrat';
    font-style: normal;
    margin-right: 13px;
    font-size: calc(18px + 5 * (100vw / 1920));
    text-decoration: none;
    font-weight: 700;
    color: #FFFFFF;
}
.active{
    color: #A8BBFF;
}
.logo__wrapper{
    text-align: right;
}

.balance_title {
    padding: 0 10px 0 0;
}

@media (max-width: 1140px) {
    .cabinet__container{
        padding: 20px 20px 20px 20px;
    }
}

@media (max-width: 425px) {
    .cabinet__container{
        padding: 25px 15px 15px 15px;
    }
}

@media (max-width: 1560px) {
    .cabinet__my-profile-wrapper{
        display: none;
    }
    .cabinet__top-info{
        display: flex;
        justify-content: space-between;
    }
    .cabinet__balance-inner{
        width: 300px;
        padding: 7px 15px 7px 15px;
        font-family: "Montserrat";
        font-size: 16px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        border: 1.37059px solid #1AFF75;
        border-radius: 5.48235px;
    }
}
@media (max-width: 1200px) {
    .cabinet__my-profile-wrapper{
        display: flex;
    }
    .cabinet__my-profile{
        font-size: 16px;
    }
    .cabinet__my-profile-wrapper img{
        width: 20px;
        height: 20px;
    }
}
